import React, { useState } from "react";

const ThankYou = () => {
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className="contact section-title mt-5">
        <div className="container text-white">
          <div className="row align-items-center">
            <div className="col-md-7 mx-auto">
              <div className="contact-title mb-5 mt-5">
                <h1 className="title-font title-font-size">Thank You</h1>
                <p className="mt-4 mb-4 title-font-2">
                  Thanks for reaching out! We're doing our happy dance 💃🕺 and will get back to you faster than you can say "supercalifragilisticexpialidocious"!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
