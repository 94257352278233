import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className="contact section-title mt-5">
        <div className="container text-white">
          <div className="row align-items-center">
            <div className="col-md-7 mx-auto">
              <div className="contact-title mb-5 mt-5">
                <h1 className="title-font title-font-size">Contact</h1>
                <p className="mt-4 mb-4 title-font-2">
                  Say Hello. If you want to extend some info, do not hesitate to
                  fill this form, we love to say ‘Hello Mate’.
                </p>
              </div>
            </div>

            <div className="col-lg-8 mx-auto text-white">
              <div className="contact-form mb-5 mt-5">
                <form action="https://public.herotofu.com/v1/4fa79bd0-a871-11ef-b84b-3558f10aabf2" method="post" accept-charset="UTF-8">
                  <div className="form-group">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        name="subject"
                        id="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-12">
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        rows="4"
                        name="message"
                        id="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      textIndent: "-99999px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      position: "absolute",
                    }}
                  >
                    <input type="text" name="_gotcha" tabindex="-1" autocomplete="off" />
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="btn mt-5">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
